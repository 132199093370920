<template>
    <CompTable ref="comp_table" title="居民基础数据库建设进度统计看板" :dataBefore="onDataBefore" :columns="getColumns()" :searchData="searchData" :dataList="dataList" @on-reset="onReset">
        <template #operate>
            <Button type="info" @click="$refs.comp_table.export()">导出</Button>
        </template>
        <template #search="evt">
            <RadioGroup v-model="evt.search.onlineType" type="button">
                <Radio label="0">全部示范社区</Radio>
                <Radio label="1">市级示范社区</Radio>
                <Radio label="2">区级示范社区</Radio>
                <Radio label="3">街镇级示范社区</Radio>
            </RadioGroup>
            <!-- <RadioGroup v-model="evt.search.level" type="button">
                <Radio :label="3">按区</Radio>
                <Radio :label="4">按街镇</Radio>
                <Radio :label="5">按社区</Radio>
            </RadioGroup> -->
            <DatePicker type="daterange" split-panels v-model="evt.search.date" placeholder="选择日期" />
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"
import Utils from "../../jointly/utils/utils"

export default {
    components: {
        CompTable,
    },

    data() {
        let columnCode = this.$core.getUrlParam("c") || ""
        let initialLevel = columnCode ? 6 : 3

        return {
            initialLevel: initialLevel,
            level: initialLevel,
            searchData: {
                columnCode,
                onlineType: null,
                level: columnCode ? 5 : 3,
            },
            dataList: null,
        }
    },

    created() {
        this.onLoadData()
    },

    methods: {
        async onDataBefore(data) {
            const res = {}

            if (data.onlineType) {
                res.onlineType = data.onlineType === "0" ? "" : data.onlineType
            }

            if (data.date) {
                res.startDate = data.date[0].replace(/^(.*)T.*$/, "$1")
                res.enDate = data.date[1].replace(/^(.*)T.*$/, "$1")
            }

            if (Object.keys(res).length > 0) {
                await this.onLoadData(res)
            }

            // if (data.level === 3) {
            //     this.dataList = this.tree
            // }

            // if (this.level !== 5) {
            //     delete data.streetCode
            // }

            // if (this.level !== 6) {
            //     delete data.orgCode
            // }

            // if (!data.orgCode) {
            //     this.level = data.level
            // }

            return false
        },

        onReset() {
            this.onLoadData()
        },

        getColumns() {
            return [
                {
                    title: "区",
                    key: "zoneName",
                    render: (h, p) => {
                        return h(
                            "p",
                            this.initialLevel !== 6
                                ? {
                                      style: {
                                          color: "#2faaf7",
                                          cursor: "pointer",
                                      },
                                      on: {
                                          click: () => {
                                              if (this.level !== 3) {
                                                  this.level = 3
                                                  this.dataList = this.tree
                                              } else {
                                                  this.level = 4
                                                  this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child
                                              }
                                          },
                                      },
                                  }
                                : null,
                            p.row.zoneName
                        )
                    },
                },
                ...(this.level >= 4
                    ? [
                          {
                              title: "街道",
                              minWidth: 120,
                              align: "center",
                              key: "streetName",
                              render: (h, p) => {
                                  return h(
                                      "p",
                                      this.initialLevel !== 6
                                          ? {
                                                style: {
                                                    color: "#2faaf7",
                                                    cursor: "pointer",
                                                },
                                                on: {
                                                    click: () => {
                                                        if (this.level !== 4) {
                                                            this.level = 4
                                                            this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child
                                                        } else {
                                                            this.level = 5
                                                            this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child.find(c => c.streetName === p.row.streetName).child
                                                        }
                                                    },
                                                },
                                            }
                                          : null,
                                      p.row.streetName
                                  )
                              },
                          },
                      ]
                    : []),
                ...(this.level >= 5
                    ? [
                          {
                              title: "社区",
                              minWidth: 150,
                              align: "center",
                              key: "orgName",
                          },
                      ]
                    : []),
                {
                    title: "社区总数",
                    key: "orgQuantity",
                    render: (h, p) => (this.level === 5 ? 1 : p.row.orgQuantity),
                },
                {
                    title: "已导入社区居民名册",
                    key: "activeArchive",
                    width: 180,
                    render: (h, p) => {
                        if (this.level !== 5) {
                            return p.row.activeArchive
                        }

                        return h(
                            "p",
                            {
                                style: {
                                    color: p.row.activeArchive > 0 ? "" : "red",
                                },
                            },
                            p.row.activeArchive > 0 ? "是" : "否"
                        )
                    },
                },
                {
                    title: "已初始化地址架构",
                    key: "activeOrg",
                    width: 180,
                    render: (h, p) => {
                        if (this.level !== 5) {
                            return p.row.activeOrg
                        }

                        return h(
                            "p",
                            {
                                style: {
                                    color: p.row.activeOrg > 0 ? "" : "red",
                                },
                            },
                            p.row.activeOrg > 0 ? "是" : "否"
                        )
                    },
                },
                {
                    title: "已同步到居民数据库",
                    key: "activeUabase",
                    width: 180,
                    render: (h, p) => {
                        if (this.level !== 5) {
                            return p.row.activeUabase
                        }

                        return h(
                            "p",
                            {
                                style: {
                                    color: p.row.activeUabase > 0 ? "" : "red",
                                },
                            },
                            p.row.activeUabase > 0 ? "是" : "否"
                        )
                    },
                },
                {
                    title: "已搭建动员体系",
                    key: "activeRoot",
                    width: 180,
                    render: (h, p) => {
                        if (this.level !== 5) {
                            return p.row.activeRoot
                        }

                        return h(
                            "p",
                            {
                                style: {
                                    color: p.row.activeRoot > 0 ? "" : "red",
                                },
                            },
                            p.row.activeRoot > 0 ? "是" : "否"
                        )
                    },
                },
            ]
        },

        onLoadData(data) {
            return Request.get("/gateway/api/symanage/pc/usbase/selectOperateLogView", data).then(res => {
                const tree = []
                const zones = this.groupBy(res, "zoneName")

                // 处理区
                Utils.eachObj(zones, (zk, zv) => {
                    const streets = this.groupBy(zv, "streetName")
                    const streetList = []

                    Utils.eachObj(streets, (sk, sv) => {
                        const street = {
                            streetName: sk,
                            zoneName: zk,
                            activeArchive: this.countBy(sv, "activeArchive"),
                            activeOrg: this.countBy(sv, "activeOrg"),
                            activeUabase: this.countBy(sv, "activeUabase"),
                            activeRoot: this.countBy(sv, "activeRoot"),
                            orgQuantity: sv.length,
                            child: sv,
                        }

                        streetList.push(street)
                    })

                    const zone = {
                        zoneName: zk,
                        activeArchive: this.countBy(zv, "activeArchive"),
                        activeOrg: this.countBy(zv, "activeOrg"),
                        activeUabase: this.countBy(zv, "activeUabase"),
                        activeRoot: this.countBy(zv, "activeRoot"),
                        orgQuantity: zv.length,
                        child: streetList,
                    }

                    tree.push(zone)
                })

                this.tree = tree
                this.dataList = tree
                this.level = 3
            })
        },

        countBy(arr, key) {
            var q = 0

            Utils.each(arr, v => {
                if (v[key] > 0) {
                    q++
                }
            })

            return q
        },

        groupBy(arr, key) {
            return arr.reduce((result, obj) => {
                const group = obj[key]
                if (!result[group]) {
                    result[group] = []
                }
                result[group].push(obj)
                return result
            }, {})
        },
    },
}
</script>

<style></style>
